import React, { useEffect, useState } from 'react';
import { Button } from "reactstrap";
import RecordRTC from 'recordrtc';
import { v4 as uuidv4 } from "uuid";
import { useFirebase } from "../../../contexts/firebase";
import VideoPreview from './VideoPreview';

// this file could benefit from a refactor to use the new hooks,
// use a state array rather than multiple state variables,
// and to separate the concerns of recording and uploading
// from the concerns of the UI
interface RecordScreenProps {
  onAnalyze: (audioUrl: string) => void;
}

const MAX_DURATION = 59; // Set your maximum duration in seconds

/**
 * Get the duration of an audio blob
 * @param audioBlob - The audio blob from RecordRTC recording
 * @returns Promise resolving to duration in seconds
 */
const getAudioBlobDuration = (audioBlob: Blob): Promise<number> => {
  return new Promise((resolve, reject) => {
    // Create audio context
    const audioContext = new (window.AudioContext)();
    
    // Create a file reader to read the blob
    const reader = new FileReader();
    
    reader.onload = async (event) => {
      try {
        if (!event.target?.result) {
          throw new Error('Failed to read audio data');
        }
        
        // Decode the audio data to get its properties
        const arrayBuffer = event.target.result as ArrayBuffer;
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        
        // Get duration in seconds
        const durationInSeconds = audioBuffer.duration;
        
        // Close the audio context to free up resources
        if (audioContext.state !== 'closed') {
          await audioContext.close();
        }
        
        resolve(durationInSeconds);
        console.log(`Audio duration: ${durationInSeconds.toFixed(2)} seconds`); // DEBUG
      } catch (error) {
        reject(new Error(`Failed to process audio blob: ${error instanceof Error ? error.message : String(error)}`));
      }
    };
    
    reader.onerror = () => {
      reject(new Error('Failed to read audio blob'));
    };
    
    // Read the blob as an array buffer
    reader.readAsArrayBuffer(audioBlob);
  });
};

const RecordScreen = ({ onAnalyze }: RecordScreenProps) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioRecorder, setAudioRecorder] = useState<RecordRTC | null>();
  const [videoRecorder, setVideoRecorder] = useState<RecordRTC | null>();
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [recordedVideo, setRecordedVideo] = useState<{ blob: Blob; url: string } | null>(null);
  const [audioTooLong, setAudioTooLong] = useState(false);  

  const [audioUrl, setAudioUrl] = useState<string | null>(null);  
  const [firebaseUrl] = useState<string>(() => `interact/_${uuidv4()}.mp4`); 
  const firebase = useFirebase();

  const getStream = async () => {
    try {
      if ('mediaDevices' in navigator === false) {
        alert('Sorry, your browser does not support media devices.');
        return;
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      if (stream.getVideoTracks().length === 0) {
        alert('Video recording not available. Trying with audio only.');
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setStream(audioStream);
      } else {
        setStream(stream);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStream();
  }, []);

  const startRecording = () => {
    try {
      if (!stream) {
        alert('No media stream available.  Is your webcam in use by another application?');
        return;
      }
      setRecordedVideo(null);
      const audioRecordRTC = new RecordRTC(stream, { type: 'audio' });
      const videoRecordRTC = new RecordRTC(stream, {
        type: 'video',
        recorderType: RecordRTC.MediaStreamRecorder,
        mimeType: 'video/webm;codecs=vp8',
      });
      setAudioRecorder(audioRecordRTC);
      setVideoRecorder(videoRecordRTC);
      audioRecordRTC.startRecording();
      videoRecordRTC.startRecording();
    } catch (error) {
      console.error(error);
    }
  };


  const stopRecording = () => {
    if (audioRecorder && videoRecorder) {
      audioRecorder.stopRecording(async () => {
        const audioBlob = audioRecorder.getBlob();
        
        try {
          // Check audio duration
          const durationInSeconds = await getAudioBlobDuration(audioBlob);
          console.log(`Recording duration: ${durationInSeconds.toFixed(2)} seconds`);
          
          // Set too long flag if duration exceeds your limit (e.g., 60 seconds)
          const isTooLong = durationInSeconds > MAX_DURATION;
          setAudioTooLong(isTooLong);
          
          if (isTooLong) {
            alert(`Your recording is ${durationInSeconds.toFixed(1)} seconds, which exceeds the maximum of ${MAX_DURATION} seconds. Please record a shorter response.`);
          }
        } catch (error) {
          console.error('Error determining audio duration:', error);
        }
        
        setAudioBlob(audioBlob);
        setAudioRecorder(null); // Set audio recorder to null after stopping
      });
      
      videoRecorder.stopRecording(() => {
        const recordedVideoBlob = videoRecorder.getBlob();
        const recordedAudioURL = URL.createObjectURL(recordedVideoBlob);
        setRecordedVideo({ blob: recordedVideoBlob, url: recordedAudioURL });
        setVideoRecorder(null); // Set video recorder to null after stopping
      });
    }
  };
  
  
  const stopRecording1 = () => {
    if (audioRecorder && videoRecorder) {
      audioRecorder.stopRecording(() => {
        const audioBlob = audioRecorder.getBlob();
        setAudioBlob(audioBlob);
        setAudioRecorder(null); // Set audio recorder to null after stopping
      });
      videoRecorder.stopRecording(() => {
        const recordedVideoBlob = videoRecorder.getBlob();
        const recordedAudioURL = URL.createObjectURL(recordedVideoBlob);
        setRecordedVideo({ blob: recordedVideoBlob, url: recordedAudioURL });
        setVideoRecorder(null); // Set video recorder to null after stopping
      });
    }
  };

  useEffect(() => {
    return () => {
      if (audioRecorder) {
        audioRecorder.destroy(); // Destroy audio recorder instance when component unmounts
      }
      if (videoRecorder) {
        videoRecorder.destroy(); // Destroy video recorder instance when component unmounts
      }
      if (stream) {
        stream.getTracks().forEach(track => {
          track.stop();
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleSubmit = async () => {
    if (audioBlob && firebaseUrl) {
      var audioRef = firebase.storage.ref(firebaseUrl);
      try {
        setResult('');
        setLoading(true);
        const formData = new FormData();

        formData.append('audio', audioBlob);

        const uploadresults = await firebase.storage.uploadBytes(audioRef, audioBlob) 
        firebase.storage.getDownloadURLForRef(audioRef).then(url => {
          setAudioUrl(url);
        });
        if (stream) {
          stream.getTracks().forEach(track => {
            track.stop();
          });
        }
        setLoading(false);
      } catch (err: any) {
        console.error("IFX:  can't submit", err?.response?.data || err.message);
        alert(err?.response?.data || err.message);
        setLoading(false);
      }
    }
  };

  return (
    <div className="wrapper">

      {!recordedVideo && (
        <div className="video-wrap">
          <VideoPreview stream={stream} />
        </div>
      )}
      {recordedVideo && (
        <div className="audio">
          <video width={500} height={500} src={recordedVideo.url} controls />
        </div>
      )}
      <div className="actions w-full flex justify-center" >  
        Record your response and submit for analysis:
        {!audioRecorder && (
          <Button           
            style={{ marginBottom: "10px", marginTop: "10px", color: "white" }}
            block
            color={"info"}
            onClick={startRecording} className="button">
            Start Recording
          </Button>
        )}
        {audioRecorder && (
          <Button 
          style={{ marginBottom: "10px", marginTop: "10px", color: "white" }}
          block
          color={"info"}
          onClick={stopRecording} className="button">
            Stop Recording...
          </Button>
        )}
        {audioBlob && (
          <Button 
            style={{ marginBottom: "10px", marginTop: "10px", color: "white" }}
            block
            color={audioTooLong ? "danger" : "info"}
            onClick={async () => {
              await handleSubmit();
              onAnalyze(firebaseUrl);
            }} 
            className="button" 
            disabled={loading || audioTooLong}>
            {loading ? 'Analyzing...' : audioTooLong ? 'Recording Too Long' : 'Analyze'}
          </Button>
        )}
      </div>

    </div>
  );
};

export default RecordScreen;

/*
        {audioBlob && (
          <Button 
          style={{ marginBottom: "10px", marginTop: "10px", color: "white" }}
          block
          color={"info"}
          onClick={async () => {
              await handleSubmit();
              onAnalyze(firebaseUrl);
          }} className="button" disabled={loading}>
            {loading ? 'Analyzing...' : 'Analyze'}
          </Button>
        )}
          */