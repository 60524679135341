import React, { useEffect, useState, useRef } from "react";
import { Progress } from "reactstrap";
import type { AnalysisResponse, AnalysisResult, Analysis, Skills } from "../../constants/analysisResultsFormat";
import { CONVERSATION_STAGE, EmbedButton, Column, headerColor } from "../../constants/conv_common";
import { ifxApiClient } from "../../helpers/api";
import { detectBrowserAndOS } from "../../helpers/detectBrowser";

// ***********************************************************
// The following helper functions support the analysis results

// *****    UI Components    *****
const AnalysisCard = ({ children }: { children: React.ReactNode }) => (
  <div
    style={{
      padding: "10px",
      boxShadow: "0px 0px 5px 4px rgba(0,0,0,0.1)",
      borderRadius: "10px",
      margin: "20px 0",
    }}
  >
    {children}
  </div>
);

// ********************************************************************************
// ****  SCORING ALGORITHMS  *****
// Scoring algorithms should be moved to a separate file
// ********************************************************************************

const PASSING = 0.8;
const NEEDS_IMPROVEMENT = 0.5;

// TODO: change this any to unknown and use guards to narrow the type safely
const getAbilityPoints = (abilityId: string, abilities: any[]) => {
  const currentAbility = abilities.find(ability => ability.ability_id === abilityId);

  return currentAbility.score * currentAbility.weight * 100;
};

const GRADED_ABILITIES = ["Themes", "Talking Points", "Key Phrases"];

interface AbilityFeedback {
  feedback: string;
  isPositive: boolean;
}

// Logic from the mobile app.
const getAbilityFeedback = (
  name: string,
  points: number,
  feedback?: string[] | string,
  feedbackExtra?: string[] | string
): AbilityFeedback[] => {
  const finalFeedbackList: AbilityFeedback[] = [];

  let feedbackList: string[] = [];
  if (feedback) {
    feedbackList = typeof feedback === "string" ? [feedback] : feedback;
  }

  let extraFeedbackList: string[] = [];
  if (feedbackExtra) {
    extraFeedbackList = typeof feedbackExtra === "string" ? [feedbackExtra] : feedbackExtra;
  }

  if (GRADED_ABILITIES.indexOf(name) < 0) {
    feedbackList.forEach(f => {
      finalFeedbackList.push({
        feedback: f,
        isPositive: points > 0,
      });
    });
  } else if (points > 0 && feedbackList.length > 0 && extraFeedbackList.length > 0) {
    feedbackList.forEach(f => {
      finalFeedbackList.push({
        feedback: f,
        isPositive: true,
      });
    });
    extraFeedbackList.forEach(f => {
      finalFeedbackList.push({
        feedback: f,
        isPositive: false,
      });
    });
  } else if (points === 0 && feedbackList.length > 0 && extraFeedbackList.length > 0) {
    extraFeedbackList.forEach(f => {
      finalFeedbackList.push({
        feedback: f,
        isPositive: true,
      });
    });
    feedbackList.forEach(f => {
      finalFeedbackList.push({
        feedback: f,
        isPositive: false,
      });
    });
  } else {
    feedbackList.forEach(f => {
      finalFeedbackList.push({
        feedback: f,
        isPositive: points > 0,
      });
    });
  }

  return finalFeedbackList;
};

const extractAbilities = (analysisResponse: {
  analyses: any[];
  // TODO: fix types
  skills: { current: { abilities: any[] } };
}) =>
  analysisResponse.analyses.map(analysis => {
    const abilityPoints = getAbilityPoints(
      analysis.ability_id,
      analysisResponse.skills.current.abilities
    );

    const abilityFeedback = getAbilityFeedback(
      analysis.app_title,
      abilityPoints,
      analysis.results.feedback,
      analysis.results.feedback_extra
    );

    return {
      name: analysis.app_title,
      description: analysis.app_description,
      priority: analysis.priority,
      numRequired: analysis.results.required ? analysis.results.required : 0,
      points: abilityPoints,
      feedback: abilityFeedback,
    };
  });

const getScoreText = (score: number) => {
  if (score < NEEDS_IMPROVEMENT) {
    return (
      <h5 className="text-danger" style={{ textAlign: "center" }}>
        Needs improvement
      </h5>
    );
  } else if (score < PASSING) {
    return (
      <h5 className="text-warning" style={{ textAlign: "center" }}>
        Almost there
      </h5>
    );
  } else {
    return (
      <h5 className="text-success" style={{ textAlign: "center" }}>
        Well done!
      </h5>
    );
  }
};

function getAbilityStatusText(
  title: string,
  priority: number,
  numTotal: number,
  numRequired: number,
  maxRequiredPriority = 2
) {
  if (priority > maxRequiredPriority) {
    return <p>Extra credit</p>;
  }

  if (GRADED_ABILITIES.indexOf(title) < 0) {
    return <p>Required to pass</p>;
  }

  return (
    <p>
      Must discuss {numRequired ? numRequired : "?"} of {numTotal} to pass
    </p>
  );
}

//  ******************  Browser and OS needed for audio STT ******************
// this has been moved into an external utility file

// function detectBrowserAndOS() {
//   const userAgent = navigator.userAgent;
//   // console.log('userAgent: ', userAgent);
  
//   let browser = "Unknown";
//   let os = "Unknown";

//   // Detect browser
//   if (userAgent.indexOf("Firefox") > -1) {
//       browser = "Firefox";
//   } else if (userAgent.indexOf("SamsungBrowser") > -1) {
//       browser = "Samsung Browser";
//   } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
//       browser = "Opera";
//   } else if (userAgent.indexOf("Trident") > -1) {
//       browser = "Internet Explorer";
//   } else if (userAgent.indexOf("Edge") > -1) {
//       browser = "Microsoft Edge (Legacy)";
//   } else if (userAgent.indexOf("Edg") > -1) {
//       browser = "Microsoft Edge (Chromium)";
//   } else if (userAgent.indexOf("Chrome") > -1) {
//       browser = "Chrome";
//   } else if (userAgent.indexOf("Safari") > -1) {
//       browser = "Safari";
//   }

//   // Detect OS
//   if (userAgent.indexOf("Win") > -1) {
//       os = "Windows";
//   } else if (userAgent.indexOf("Mac") > -1) {
//       os = "macOS";
//   } else if (userAgent.indexOf("Linux") > -1) {
//       os = "Linux";
//   } else if (userAgent.indexOf("Android") > -1) {
//       os = "Android";
//   } else if (userAgent.indexOf("like Mac") > -1) {
//       os = "iOS";
//   }

//   return { browser, os };
// }

// ************************************************************************************************

interface StoryAnalysisScreenProps {
  storyId: string;
  sceneId: string;
  lastScene?: boolean;
  userId: string;
  interactUserId: string;  
  audioUrl?: string;
  analysisResponse: any;  // Consider adding a more specific type here
  onStageChange: (newStage: CONVERSATION_STAGE) => Promise<void>;
  onContinueConversation: () => Promise<void>;
  onAnalysisResponse: (newAnalysisResponse: any) => void;  // Match the type to your analysis response structure
}

interface UtterancePayload {
  uid: string;
  storyid: string;
  sceneid: string;
  uri: string | undefined;
  localfilename: string | null;
  localaudiofilename: string | null;
  method: string;
  webBrowser: string;
  os: string;
}
// first draft of the error handling; should move to analysisResultsFormat.ts when stable
interface AnalysisError {
  type: 'NO_AUDIO' | 'NETWORK_ERROR' | 'INVALID_RESPONSE' | 'UNKNOWN';
  message: string;
  details?: any;  // For additional error information we might want to log
}

// ************************************************************************************************
// The Main Function:   AnalysisScreen

const AnalysisScreen = ({
  storyId,
  sceneId,
  lastScene,
  userId,
  interactUserId,
  audioUrl,
  onStageChange,
  onAnalysisResponse,
  onContinueConversation,
  analysisResponse: upstreamAnalysisResponse,
}: StoryAnalysisScreenProps) => {
    // clearly separate local and upstream state:
    const [analysisState, setAnalysisState] = useState<{
      isLoading: boolean;
      error?: AnalysisError;
      responseData?: AnalysisResponse | null;
    }>({
      isLoading: false,
      responseData: upstreamAnalysisResponse || null,
    });

  // const callRef = useRef(false);

  console.info("IFX: Entered AnalysisScreen:"); // DEBUG

  const performAnalysis = async (payload: UtterancePayload) => {
    try {
      const httpResponse = await ifxApiClient.post<AnalysisResponse>("/analysis/analyzeembed", payload);
      const analysisData = httpResponse.data;
      if (!analysisData?.analyses) {
        // Handle invalid response by updating state
        setAnalysisState(prevState => ({
          ...prevState,
          error: {
            type: 'INVALID_RESPONSE',
            message: 'We could not detect anyone speaking. Please try again.',
            details: { receivedData: analysisData }
          },
          isLoading: false,
          responseData: null
        }));
        return null;
      }
    // Update our state with just the analysis data
      setAnalysisState(prevState => ({
        ...prevState,
        responseData: analysisData,  // This is now just the AnalysisResponse
        isLoading: false
      }));
    // Pass just the analysis data to our callback
    onAnalysisResponse?.(analysisData);
    return analysisData;  // Return just the analysis data

    } catch (error) {
      let analysisError: AnalysisError;
      // should have if's here for different error types
        analysisError = {
          type: 'NO_AUDIO',
          message: 'There was an audio processing difficulty. Please try again.',
          details: error
        };
        console.error("Analysis failed:", analysisError);
            // Update state with the categorized error
      setAnalysisState(prevState => ({
        ...prevState,
        error: analysisError,
        isLoading: false,
        responseData: null
      }));
      return null;
    }
  };

  useEffect(() => {
      // Only start analysis if we don't have a response and aren't already loading
      if (analysisState.responseData || analysisState.isLoading) {
        return;
      }

    const browserAndOS = detectBrowserAndOS();
    // console.log("********************************************");
    // console.log("Interaction AnalysisScreen about to post content to analysis/analyzeembed ");
    // console.log("St, Sc, uid iuid: ", storyId, sceneId, userId, interactUserId);
    // console.log("vid, br, os", audioUrl, browserAndOS.browser, browserAndOS.os);
    const analysisUID = interactUserId || userId;
    // console.info("IFX: AnalysisScreen analysisUID: ", analysisUID);
    const utterancePayload = {
      uid: analysisUID,
      storyid: storyId,
      sceneid: sceneId,
      uri: audioUrl,
      localfilename: null,
      localaudiofilename: null,
      method: "web",
      webBrowser: browserAndOS.browser,
      os: browserAndOS.os,
      };

    performAnalysis(utterancePayload);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let content;

  if (analysisState.error) {
    console.error("IFX: AnalysisScreen Error analyzing the utterance", analysisState.error);
    const errorMessage = analysisState.error.message;
    content = (
      <div
        style={{
          marginTop: "100px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Column>
          <h5
            style={{
              textAlign: "center",
            }}
          >
            { analysisState.error.message }
          </h5>
          <div>
            <EmbedButton
              onClick={() => {
                setAnalysisState(prev => ({ ...prev, error: undefined }));
                onAnalysisResponse?.(null);
                onStageChange(CONVERSATION_STAGE.RESPONSE_VIDEO_RECORDING);
              }}
            >
              Record again
            </EmbedButton>
          </div>
        </Column>
      </div>
    );
  } else if (!analysisState.responseData) {
    content = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <h4>Analyzing…</h4>
      </div>
    );
  } else {
    const parsedAbilities = extractAbilities(analysisState.responseData);
    const score = analysisState?.responseData?.skills?.current.score || 0;

    content = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Column>
          <h3 style={{ color: headerColor }}>
            Overall Effectiveness
            </h3>
          <AnalysisCard>
            <h4 style={{ color: headerColor }}>{analysisState?.responseData?.skills?.name}</h4>

            <h2 style={{ color: headerColor, textAlign: "center" }}>
              {analysisState?.responseData?.skills?.current.score * 100}
            </h2>
            <Progress value={score * 100} />

            <div style={{ marginTop: "20px" }}>{getScoreText(score)}</div>
          </AnalysisCard>

          <div>
            {score >= PASSING && ( <div>
              <EmbedButton
                  onClick={() => {
                    if (lastScene) onStageChange(CONVERSATION_STAGE.STORY_PASSED)
                    else {
                      onAnalysisResponse?.(null);
                      onContinueConversation();
                    }
                  }}
                >
                {lastScene ? "Congratulations!" : "Continue the Conversation"}     
                </EmbedButton>
                </div> ) }

            <EmbedButton
              onClick={() => {
                onAnalysisResponse?.(null);
                onStageChange(CONVERSATION_STAGE.INTERACTION_VIDEO);
              }}
            >
              Try again
            </EmbedButton>
            <EmbedButton
              onClick={() => {
                onStageChange(CONVERSATION_STAGE.EXPERT_VIDEO);
              }}
            >
              View a Good Response
            </EmbedButton>
            <EmbedButton
              onClick={() => {
                onStageChange(CONVERSATION_STAGE.STORY_QUIT);
              }}
            >
              {lastScene ? "Done" : "Quit"}
            </EmbedButton>
          </div>
        </Column>

        <Column>
          <h3 style={{ color: headerColor }}>Feedback</h3>
          {parsedAbilities.map(({ name, description, points, feedback, numRequired, priority }) => (
            <AnalysisCard key={name}> 
              <h4 style={{ color: headerColor }}>{name}</h4>
              <p style={{ color: "gray", marginLeft: "20px" }}>
              </p>
                {getAbilityStatusText(name, priority, parsedAbilities.length, numRequired)}
              <p>{description}</p>

              <div style={{ margin: "10px 0" }}>
                {feedback.map(({ feedback, isPositive }) => {
                  const icon = isPositive ? "icon-plus" : "icon-minus";
                  const color = isPositive ? "text-success" : "text-danger";
                  return (
                    <span className={color} key={feedback}>
                      {" "}
                      <i className={icon} /> {feedback}
                      <br />
                    </span>
                  );
                })}
              </div>

              <p style={{ textAlign: "right" }} className={points ? "text-success" : "text-danger"}>
                {points} points
              </p>
            </AnalysisCard>
          ))}
        </Column>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        minHeight: "100%",
        maxWidth: "100%",
      }}
    >
      {content}
    </div>
  );
};

export default AnalysisScreen;
