// this is the new module that will guide the user through an entire story 
//  NOTE ON FEATURES:  there are two features not fully implemented: 
//  1.  starting on a scene other than the first scene in the story.  startSceneID is ignored.
//  2.  assessmentMode is not used.  
import axios from "axios";
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import RecordScreen from "../../../components/Interact/RecordScreen";
import StoryAnalysisScreen from "../../../components/Interact/StoryAnalysisScreen";
import VideoScreen from "../../../components/Interact/VideoScreen";
import { apiRoot } from "../../../constants";
import { CONVERSATION_STAGE, ConversationProps, Column, EmbedButton, headerColor } from "../../../constants/conv_common";
import { useAuth } from "../../../contexts/authUser";
import { ifxApiClient } from "../../../helpers/api";
const goldMedalImg = require("../../../assets/img/GoldMedalClean.png");

// *****    UI Components    *****
const TitleCard = ({ children }: { children: React.ReactNode }) => (
  <div
    style={{
      padding: "10px",
      boxShadow: "0px 0px 5px 4px rgba(0,0,0,0.1)",
      borderRadius: "10px",
      margin: "20px 0",
      width: "100%",           // Take up full column width
      display: "flex",         // Use flexbox for centering
      flexDirection: "column", // Stack children
      alignItems: "center", // Center horizontally
      }}> 
    {children}
  </div>
);

// ********* some data structures  *********

interface SceneDetails {
  id: string;
  title: string;
  description: string;
  summary: string;
  video_interaction_type: string;
  video_interaction_name: string;
  video_professional_name: string;
  video_professional_type: string;
  };

interface StoryDetails {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  scenes: SceneDetails[];
}

  // Keep all state info (except isVideoPaused) in a single interface
  interface ConversationState {
    stage: CONVERSATION_STAGE;
    activeSceneId: string;
    activeSceneIndex: number;
    finalSceneIndex: number;
    interactionVideoUrl: string;
    expertVideoUrl: string;
    storyThumbNailUrl: string;
    userAudioUrl?: string;
    analysisResponse: any;
    storyDetails: StoryDetails;
  }
 
// ***********************************************************
//  Here is the main entry point: 

const EmbeddedConversation = ({
    storyToAnalyze, 
    startSceneId, 
    interactUserId,
    // assessmentMode,
    }: ConversationProps) => {

    const auth = useAuth();
    const currUserId = auth.authUser?.uid || "";
  
    // Centralize all state in one useState call
    const [state, setState] = useState<ConversationState>({
      stage: CONVERSATION_STAGE.INITIALIZING,
      activeSceneId: startSceneId,
      activeSceneIndex: 0,
      finalSceneIndex: 0,
      interactionVideoUrl: "",
      expertVideoUrl: "",
      storyThumbNailUrl: "",
      analysisResponse: null,
      storyDetails: {
        id: storyToAnalyze,
        title: "",
        description: "",
        thumbnail: "",
        scenes: [], 
      },
     });
  let storyThumbNailUrl = "";
  const [isVideoPaused, setIsVideoPaused] = useState<boolean | undefined>();
  // const [isInIframe, setIsInIframe] = useState(true);

  // Here's our Helper function to update state (including partial updates)
  const updateState = (updates: Partial<ConversationState>) => {
    setState(prev => ({ ...prev, ...updates }));
  };

// here's our function to close the window if they successfully completed the story
  const handleClose = () => {
    // Try to close the window
    window.close();
    
    // If we reach this point, window.close() probably didn't work
    // Provide a fallback message
    alert("Unable to close automatically. This browser restricts window.close(). Please close this tab manually.");
  };

    // ***********************************************************
    // Define our data fetching functions

    const fetchStoryInfo = async (storyId: string) => {
      const userToken = auth.idToken;
      try {
          const endPoint = apiRoot+'/stories/embed/'+storyId; 
          // console.info('Calling endpoint:', endPoint);
          console.info("calling ", endPoint, 'with authtoken:', userToken);
          const response = await axios({ 
              method: 'get', 
              url: endPoint, 
              headers: { 'Authorization': 'Bearer ' + userToken } 
          });
          console.info("fetchStoryInfo got story data: ", response.data); // DEBUG
          return response.data as StoryDetails;

      } catch (error) {
          console.error("Error getting story info", error);
        throw error;
      }
      // try {
      //   const response = await ifxApiClient.get(`stories/embed/${storyId}`);
      //   return response.data as StoryDetails;
      // } catch (error) {
      //   console.error("Error fetching story:", error);
      //   throw error;
      // }
    };
  
    const fetchVideoUrl = async (fileName: string) => {
      const storage = getStorage();
      try {
        const storageRef = ref(storage, fileName);
        return await getDownloadURL(storageRef);
      } catch (error) {
        console.error("Error fetching video URL:", error);
        throw error;
      }
    };
    
    // ***********************************************************
    // ok, let's get started:  first we check if we're in an iframe,
      
      // useEffect(() => {
      //   // Check if the current window is in an iframe when component mounts
      //   const checkIfInIframe = () => {
      //     try {
      //       return window.self !== window.top;
      //     } catch (e) {
      //       // If accessing window.top throws an error due to same-origin policy,
      //       // we're definitely in an iframe from another domain
      //       return true;
      //     }
      //   };
        
      //   setIsInIframe(checkIfInIframe());
      // }, []);
    
     // then we Initialize conversation:
     useEffect(() => {
        let isMounted = true;

        console.log("Starting initialization"); // DEBUG
        const initializeConversation = async () => {
          try {
            // console.info( "about to fetch; auth.authUser = ", auth.authUser); // DEBUG
            const storyDetails = await fetchStoryInfo(storyToAnalyze);
            if (!isMounted) return;
            console.info("intialization isMounted; StoryDetails fetched:", storyDetails); // DEBUG
            const lastSceneIndex = storyDetails.scenes.length - 1; 
            const initialSceneId = storyDetails.scenes[0].id;  // IFX:  startSceneId is ignored
            // const initialSceneId = startSceneId || storyDetails.scenes[0].id;  // for next version.
            const currentSceneIndex = 0; // IFX:  startSceneId is ignored
            // const currentSceneIndex = storyDetails.scenes.findIndex(
            //   scene => scene.id === initialSceneId
            // );
            // if (currentSceneIndex === -1) {
            //   throw new Error(
            //     `Could not find scene ${initialSceneId} in story ${storyDetails.title}`
            //   );
            // }
            console.log("initializeConversation returned with storyDetails for : ",storyDetails.title); // DEBUG
            // Get the scene we're working with
            const currentScene = storyDetails.scenes[currentSceneIndex];
            console.log("Initializing with scene:", currentScene); // DEBUG

            // Construct the video filenames
            const interactionFileName = `${currentScene.video_interaction_name}.${currentScene.video_interaction_type}`;
            const expertFileName = `${currentScene.video_professional_name}.${currentScene.video_professional_type}`;
            const storyThumbnailName = storyDetails.thumbnail;

            console.log("Fetching video URLs for:", {
              interactionFileName,
              expertFileName,
              storyThumbnailName,
            });
            try {
            const [interactionVideoUrl, expertVideoUrl, storyThumbUrl] = await Promise.all([
              fetchVideoUrl(interactionFileName),
              fetchVideoUrl(expertFileName),
              fetchVideoUrl(storyThumbnailName),
            ]);
    
            if (isMounted) {
              updateState({
              storyDetails: storyDetails,
              activeSceneId: initialSceneId,
              activeSceneIndex: currentSceneIndex,
              finalSceneIndex: lastSceneIndex,
              interactionVideoUrl: interactionVideoUrl,
              expertVideoUrl: expertVideoUrl,
              storyThumbNailUrl: storyThumbUrl,
              stage: CONVERSATION_STAGE.STORY_OVERVIEW,
            });
          }

          console.log("Initialization complete for story:", storyDetails.title);

          } catch (videoError) {
            // Handle video URL fetching errors separately
            console.error("Error fetching video URLs:", videoError);
            // updateState({
            //   stage: CONVERSATION_STAGE.ERROR,
            //   error: "Could not load video content. Please try again later."
            // });
        }  } catch (error) {
            console.error("Error initializing conversation:", error);
            // Handle error appropriately
          }
        };
        initializeConversation();
        return () => {
          isMounted = false;
        };
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
 // Handle scene transitions
  const moveToNextScene = async () => {
    console.log("moveToNextScene starting with story: ",state.storyDetails); // DEBUG
    const currentSceneIndex = state.storyDetails.scenes.findIndex(
      scene => scene.id === state.activeSceneId
    );
    console.info("moveToNextScene currentScene has index: ",currentSceneIndex); // DEBUG
    if (currentSceneIndex === -1 || currentSceneIndex === state.storyDetails.scenes.length - 1) {
      console.info("Story passed"); // DEBUG
      updateState({ stage: CONVERSATION_STAGE.STORY_PASSED });
      return;
    }

    const nextSceneIndex = currentSceneIndex + 1;
    const nextSceneId = state.storyDetails.scenes[nextSceneIndex].id;
    console.info("moveToNextScene has nextSceneId: ", nextSceneId); // DEBUG

    try {
      const interactionFileName = 
        `${state.storyDetails.scenes[nextSceneIndex].video_interaction_name}.${state.storyDetails.scenes[nextSceneIndex].video_interaction_type}`;
      const expertFileName = 
        `${state.storyDetails.scenes[nextSceneIndex].video_professional_name}.${state.storyDetails.scenes[nextSceneIndex].video_professional_type}`;

      const [interactionVideoUrl, expertVideoUrl] = await Promise.all([
        fetchVideoUrl(interactionFileName),
        fetchVideoUrl(expertFileName),
      ]);

      updateState({
        activeSceneId: nextSceneId,
        activeSceneIndex: nextSceneIndex,
        interactionVideoUrl: interactionVideoUrl,
        expertVideoUrl: expertVideoUrl,
        stage: CONVERSATION_STAGE.INTERACTION_VIDEO,
      });
    } catch (error) {
      console.error("Error moving to next scene:", error);
      // Handle error appropriately
    }
  };

  // console.info ("EmbeddedConversation about to render"); // DEBUG
  // console.info ("story id passed in:", storyToAnalyze); // DEBUG
  // console.info ("retrieved story title:", state.storyDetails.title); // DEBUG
  // console.info ("StoryDetails:", state.storyDetails); // DEBUG
  //   // ----------------------------------------------------------------
  // Render loading state
  if (state.stage === CONVERSATION_STAGE.INITIALIZING) {
    return (
      <div className="flex h-screen w-screen items-center justify-center p-5">
        <h5>Loading Conversation...</h5>
      </div> 
    );
  }

    // Main render
return (
  <div className="w-full flex flex-col items-center justify-start border-5 border-solid border-sky-200 rounded-lg p-5">
    {state.stage === CONVERSATION_STAGE.STORY_OVERVIEW && (
      <div style ={{ textAlign: "center" }}>
        <Column>
        <img
            style={{ margin: 0, padding: 0, alignContent: "center", maxWidth: "500px" }}
            src={state.storyThumbNailUrl}
            alt="Story Thumbnail"
          />

          <TitleCard>
          <h2 style={{ color: headerColor, textAlign: "center" }}>
            {state.storyDetails.title}
          </h2>
          <p>{state.storyDetails.description}</p>
          <p> Click when you're ready to start the conversation:</p>
          </TitleCard>
          <EmbedButton
          onClick={() => updateState({ stage: CONVERSATION_STAGE.INTERACTION_VIDEO })}
        >
          Start the conversation
        </EmbedButton>
        </Column>
      </div>
    )}

    {state.stage === CONVERSATION_STAGE.INTERACTION_VIDEO && (
        <div>
          <Column>
            <TitleCard>
              <h4 style={{ color: headerColor, textAlign: "center" }}>        
                {state.storyDetails.scenes[state.activeSceneIndex].title}</h4>
              <p>{state.storyDetails.scenes[state.activeSceneIndex].description}</p>
            </TitleCard>

          <div style={{ 
                width: "100%",           // Take up full column width
                display: "flex",         // Use flexbox for centering
                justifyContent: "center" // Center horizontally
                }}>
          <VideoScreen
            videoUrl={state.interactionVideoUrl}
            autoPlay
            paused={isVideoPaused}
            onVideoEndOrPaused={() => {
              setIsVideoPaused(true);
            }}
            onVideoPlay={() => {
              setIsVideoPaused(false);
            }}
          />
            </div>
            <div style={{ 
                display: "flex",         // Use flexbox for centering
                justifyContent: "center" // Center horizontally
              }}> 
          <EmbedButton
            onClick={() => {
              setIsVideoPaused(prev => !prev);
            }}
          >
            {isVideoPaused ? "Listen" : "Pause"}
          </EmbedButton>
          </div>
            <div style={{ 
                display: "flex",         // Use flexbox for centering
                justifyContent: "center" // Center horizontally
              }}> 
          <EmbedButton
            onClick={() => {
              updateState({ stage: CONVERSATION_STAGE.RESPONSE_VIDEO_RECORDING });
            }}
          >
            Respond
          </EmbedButton>
          </div>
          </Column>
      </div>
    )}

    {state.stage === CONVERSATION_STAGE.RESPONSE_VIDEO_RECORDING && (
        <div>
        <Column>
            <TitleCard>
              <h4 style={{ color: headerColor, textAlign: "center" }}>
                {state.storyDetails.scenes[state.activeSceneIndex].title}
              </h4>
              <p>{state.storyDetails.scenes[state.activeSceneIndex].description}</p>
              <p>{state.storyDetails.scenes[state.activeSceneIndex].summary}</p>
            </TitleCard>
        <RecordScreen
          onAnalyze={audioUrl => {
            updateState({userAudioUrl: audioUrl});
            updateState({ stage: CONVERSATION_STAGE.ANALYSIS });
          }}
        />
        </Column>
      </div>
    )}

    {state.stage === CONVERSATION_STAGE.ANALYSIS &&  (
       <div>
        <StoryAnalysisScreen
          storyId={storyToAnalyze}
          sceneId={state.activeSceneId}
          lastScene={state.activeSceneIndex === state.finalSceneIndex}
          userId={currUserId}
          interactUserId={interactUserId}
          audioUrl={state.userAudioUrl}
          analysisResponse={state.analysisResponse}
          onStageChange={async newStage => {
            if (newStage) {
              updateState({ stage: newStage });
            }
          } } 
          onContinueConversation={ moveToNextScene }
          onAnalysisResponse={(newAnalysisResponse) => {
            updateState({ analysisResponse: newAnalysisResponse });
          }}
        />
        </div>
      )}

    {state.stage === CONVERSATION_STAGE.EXPERT_VIDEO && (
        <div>
          <TitleCard>
          <h4 style={{ color: headerColor, textAlign: "center" }}>
              Effective Response
              </h4>
          <p>Try responding with something like this:</p>
          </TitleCard>
          <VideoScreen videoUrl={state.expertVideoUrl} />
          <EmbedButton
            onClick={() => {
              updateState({ analysisResponse: null });
              updateState({ stage: CONVERSATION_STAGE.INTERACTION_VIDEO });
            }}
          >
            Try again
          </EmbedButton>
          <EmbedButton
            onClick={() => {
              updateState({ stage: CONVERSATION_STAGE.ANALYSIS });
            }}
          >
            Go back to feedback
          </EmbedButton>
        </div>
      )}

    {state.stage === CONVERSATION_STAGE.STORY_PASSED && (
        <div>
          <Column>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{ margin: 0, padding: 0 }}
                src={goldMedalImg}
                alt="A gold medal"
              />
            </div>
          <TitleCard>
          <h1 style={{ color: headerColor, textAlign: "center" }}>
            Congratulations!</h1>
            <h3 style={{textAlign: "center"}} >
              You successfully navigated another challenging conversation.</h3>  
          </TitleCard>
          </Column>         
        </div>
        )}

    {state.stage === CONVERSATION_STAGE.STORY_QUIT && (
      <div>
        <TitleCard>
          <h2 style={{ color: headerColor, textAlign: "center" }}>
            Sorry to see you go</h2>
          <p>Would you like jump back in?</p>  
        </TitleCard>
        <EmbedButton 
        onClick={() => updateState({ stage: CONVERSATION_STAGE.INTERACTION_VIDEO})}>
        Jump back in
        </EmbedButton>
    </div>
    )}
  </div>
  );
};

export default EmbeddedConversation;

/*
{!isInIframe && (
  <EmbedButton
    onClick={handleClose}>
      Close this tab
    </EmbedButton>
)}
    */