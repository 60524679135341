export const ROOT_URL = "/";
export const HOME_URL = "/home";

export const LOGIN_URL = "/login";
export const PASSWORD_RESET_URL = "/passwordreset";

export const ERROR_404_URL = "/404";
export const ERROR_500_URL = "/500";

export const REPORTING_OVERVIEW_URL = "/reporting";

export const ASSIGN_CONTENT_URL = "/assign-content";

export const COMPANY_URL = "/company";
export const COMPANY_ROLES_URL = "/company/roles";

export const CONTENT_OVERVIEW_URL = "/content";

export const CUSTOMERS_URL = "/customers";
export const CUSTOMER_CREATE_URL = "/customer/create";

export const SCENES_OVERVIEW_URL = "/scenes";
export const SCENE_DETAIL_URL = "/scenes/:id";
export const SCENE_WIZARD_URL = "/scene-wizard2/:id";

export const SKILLS_CREATE_URL = "/skills-create";
export const SKILLS_OVERVIEW_URL = "/skills";
export const SKILL_DETAIL_URL = "/skills/:skill_id";

export const STORIES_OVERVIEW_URL = "/stories";
export const STORY_CREATE_URL = "/story-create";
export const STORY_DETAIL_URL = "/stories/:id";

export const USERS_MANAGEMENT_URL = "/users";
export const USER_CREATE_URL = "/users/create";
export const USER_DETAILS_URL = "/users/:userId";

export const USER_PROFILE_URL = "/user/profile";
export const USER_CURRICULUM_URL = "/user/curriculum";
export const USER_PROGRESS_URL = "/user/progress";
export const USER_CONVERSATION_URL = "/user/story/:storyId/:sceneId";

export const STORY_EMBED_URL = "/embed/:storyId/:sceneId";
export const STORYUSER_EMBED_URL  = "/embed/:storyId/:sceneId/:userId";
export const SCENE_INTERACT_URL  = "/interact/:storyId/:sceneId";
export const SCENEUSER_INTERACT_URL = "/interact/:storyId/:sceneId/:userId";

export const MAGIC_PYTHON_URL = "/magic-python";
export const MAGIC_URL = "/magic";
export const SEMANTIC_SIMILARITY_URL = "/magic3";
